import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClient from '../HttpClient';
import HttpClientV2 from '../HttpClientV2';

const ItemState = {
  /**
   * No object has been selected, the variable is null.
   */
  NOT_SELECTED: 'NOT_SELECTED',
  /**
   * An object that does not represent a row in the database is considered as TRANSIENT. When the object is in TRANSIENT state then it will not contain any identifier (primary key value).
   */
  TRANSIENT: 'TRANSIENT',
  /**
   * An object representing a row in the database and has an identifier value is considered PERSISTENT.
   */
  PERSISTENT: 'PERSISTENT',
  /**
   * An object that represents a row in the database and has an identifier value, but some attributes differ from the persisted object (it was modified) is considered DETACHED.
   */
  DETACHED: 'DETACHED',
};

const Operation = {
  NONE: 'NONE',
  IS_GETTING: 'IS_GETTING',
  IS_CREATING: 'IS_CREATING',
  IS_UPDATING: 'IS_UPDATING',
  IS_DELETING: 'IS_DELETING',
};

export default function EntityStore(className, context = {}) {
  const state = reactive({
    operation: Operation.NONE,
    items: [],
    total: 0,
    filters: {},
    options: {
      rowsPerPage: 1000,
      page: 0,
    },
    item: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
  });

  const actions = {
    async applyFilter({ key, value }) {
      if (_.isEmpty(value)) {
        delete state['filters'][key];
      } else {
        _.set(state, `filters.${key}`, value);
      }
      if (_.isEqual(_.get(state, 'operation'), Operation.IS_GETTING)) {
        setTimeout(async () => {
          await this.loadItems();
        }, 500);
      } else {
        await this.loadItems();
      }
    },

    async removeFilter({ key }) {
      delete state['filters'][key];
      await this.loadItems();
    },

    async applyOptions({ value }) {
      _.set(state, 'options', value);
      await this.loadItems();
    },

    async runRemoteFunction(name, parameters = {}) {
      try {
        return await HttpClient.runProcess(className, name, parameters);
      } catch (error) {
        return null;
      }
    },

    async loadItems(sortBy = 'name') {
      try {
        _.set(state, 'operation', Operation.IS_GETTING);
        const params = {
          limit: 0,
          page: 0,
          disablePagination: 'true',
        };
        let result = await HttpClientV2.callFunctionV2('GET', className, params);
        _.set(state, 'items', _.sortBy(result.resultSet, [sortBy]));
        _.set(state, 'total', result.total);
      } catch (error) {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
      } finally {
        _.set(state, 'operation', Operation.NONE);
      }
    },

    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
    },

    async unselectItem() {
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
    },

    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },

    async editItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if ([ItemState.PERSISTENT, ItemState.TRANSIENT, ItemState.DETACHED].includes(itemState)) {
        _.set(state, 'isEditing', true);
      } else {
        throw new Error('The item must be in the PERSISTENT, TRANSIENT or DETACHED state to edit.');
      }
    },

    async saveItem() {
      if (context.refs.form) {
        let validation = context.refs.form.validate();
        if (!validation) return;
      }
      if (state.itemState == ItemState.TRANSIENT) {
        try {
          _.set(state, 'operation', Operation.IS_CREATING);
          await actions.unselectItem();
          return await actions.loadItems();
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else if (state.itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_UPDATING);
          await actions.unselectItem();
          return await actions.loadItems();
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the TRANSIENT or DETACHED state to save.');
      }
    },

    async deleteItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.PERSISTENT || itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_DELETING);
          await this.unselectItem({});
          await this.loadItems();
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the PERSISTENT or DETACHED state to delete.');
      }
    },

    async updateAttribute({ key, value }) {
      _.set(state, `${key}`, value);
    },

    async updateItemAttribute({ key, value }) {
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },

    async addToArrayNested({ key, value }) {
      let attribute = _.get(state.item, key);
      if (_.isNil(attribute)) {
        attribute = [];
      }
      if (Array.isArray(attribute)) {
        attribute.push(value);
        await this.updateItemAttribute({ key: key, value: attribute });
      }
    },
    /**
     *
     * @param {*} param0
     */
    async updateOnArrayNested({ key, index, value }) {
      let attribute = _.get(state.item, key);
      if (!_.isNil(attribute)) {
        if (Array.isArray(attribute) && index > -1 && index < attribute.length) {
          attribute[index] = value;
          await this.updateItemAttribute({ key: key, value: attribute });
        }
      }
    },
    /**
     *
     * @param {*} param0
     */
    async updateAttributeOnArrayNested({ key, index, attributeKey, attributeValue }) {
      let attribute = _.get(state.item, key);
      if (!_.isNil(attribute)) {
        if (Array.isArray(attribute) && index > -1 && index < attribute.length) {
          attribute[index][attributeKey] = attributeValue;
          await this.updateItemAttribute({ key: key, value: attribute });
        }
      }
    },
    /**
     *
     * @param {*} param0
     */
    async deleteOnArrayNested({ key, index }) {
      let attribute = _.get(state.item, key);
      if (!_.isNil(attribute)) {
        if (Array.isArray(attribute) && index > -1 && index < attribute.length) {
          attribute.splice(index, 1);
          await this.updateItemAttribute({ key: key, value: attribute });
        }
      }
    },

    async markAsModified() {
      let itemState = _.get(state, 'itemState');
      if (itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
  };
  return { state, actions };
}
