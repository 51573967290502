<template>
  <v-container class="pt-0 pb-0" fluid>
    <v-responsive>
      <v-toolbar flat color="transparent" dense v-if="contentBar">
        <v-icon class="mr-5 primary--text">{{ $router.currentRoute.meta.icon }}</v-icon>
        <slot name="contentBar" />
      </v-toolbar>
      <v-card class="overflow-hidden" color="transparent" elevation="0" tile v-if="properties">
        <v-responsive>
          <v-sheet class="overflow-y-auto ma-0 pa-0" color="transparent">
            <slot name="properties" />
          </v-sheet>
        </v-responsive>
      </v-card>
      <v-container fluid>
        <slot name="content" />
      </v-container>
    </v-responsive>
  </v-container>
</template>
<script>
export default {
  name: 'pcc-container-properties',
  data: () => ({}),
  props: {
    contentBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    properties: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style scoped>
.specificElement {
  height: calc(100vh - 100px);
  box-sizing: border-box;
}
</style>
