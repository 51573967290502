<template>
  <pcc-button-tooltip
    v-if="show"
    v-on:click="hideEvent"
    :icon="hiddenIcon"
    position="bottom"
    :tooltip="hiddenTooltip"
    :badge="badge"
  />
  <pcc-button-tooltip
    v-else-if="!show"
    v-on:click="showEvent"
    :icon="showenIcon"
    position="bottom"
    :tooltip="showenTooltip"
    :badge="badge"
  />
</template>
<script>
export default {
  name: 'pcc-button-show-hide',
  components: {
    'pcc-button-tooltip': () => import('./PccButtonTooltip'),
  },
  data() {
    return {};
  },
  props: {
    show: Boolean,
    showenIcon: {
      type: String,
      default: 'expand_more',
    },
    hiddenIcon: {
      type: String,
      default: 'expand_less',
    },
    showenTooltip: String,
    hiddenTooltip: String,
    badge: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    showEvent() {
      this.$emit('show');
    },
    hideEvent() {
      this.$emit('hide');
    },
  },
};
</script>
