<template>
  <span class="d-flex mb-3">
    <v-autocomplete
      :items="macros.state.items"
      item-value="macro"
      item-text="macro"
      v-model="selection"
      :label="$t('commons.operation.selectAvailableMacros')"
      :loading="macros.state.loading"
      multiple
      solo
      chips
      hide-details
      dense
      flat
      solo-inverted
      clearable
    >
      <template v-slot:selection="data">
        <v-chip small label class="ma-1">
          {{ data.item.macro }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        {{ data.item.macro }}
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" class="ml-2" v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ data.item.description }}</span>
        </v-tooltip>
      </template>
    </v-autocomplete>
    <v-btn
      class="primary ml-5"
      style="text-transform: unset"
      @click="$emit('addMacros', getMacrosURL), (selection = [])"
      >{{ $t('commons.operation.addMacros') }}</v-btn
    >
  </span>
</template>
<script>
import { reactive, onMounted } from '@vue/composition-api';
import EntityStore from '../../store/private/EntityStore';

export default {
  name: 'macros-glossary',
  props: {},
  setup(context) {
    const macros = EntityStore('Macros', context);
    const selection = reactive([]);

    onMounted(async () => {
      await macros.actions.loadItems('macro');
    });

    return { macros, selection };
  },
  computed: {
    getMacrosURL() {
      let macrosURL = '';
      for (const macro of this.selection) {
        macrosURL += `{${macro}}`;
      }
      return macrosURL;
    },
  },
};
</script>
